import { computed } from "vue";
// Composables
import useOptions from "@/composables/useOptions";

/**
 * @param {Ref<Object>} value
 * @param {Ref<Object>} options
 * @returns
 */
export default function useRegistrationForm(props, value, options, isCreate) {
  // Composables
  const { ALL_OPTIONS } = useOptions();

  // Computed
  const registeredViaOptions = computed(() => {
    return options.value.registered_via.map(option => ({
      ...option,
      disabled: option !== ALL_OPTIONS.INSTRUCTOR_ACTION.value
    }));
  });

  const roleOptions = computed(() => {
    return props.data?.roles?.map(role => {
      return {
        text: role?.role?.name,
        value: role.id
      };
    });
  });

  const roleValues = computed(() => {
    return props.data?.roles?.map(role => role.id);
  });

  const hasResult = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const completed = ALL_OPTIONS.COMPLETED.value;
    const cancelled = ALL_OPTIONS.CANCELLED.value;

    // eslint-disable-next-line
    return [completed, cancelled].includes(value.value.status) && value.value.result;
  });

  const isInProgress = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const statuses = getSlicedStatuses(ALL_OPTIONS.IN_PROGRESS.value);

    return statuses.includes(value.value.status);
  });

  const isRegistered = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const statuses = getSlicedStatuses(ALL_OPTIONS.PENDING.value);

    return statuses.includes(value.value.status);
  });

  const isRegistrationRequested = computed(() => {
    if (isCreate.value) {
      return false;
    }

    // eslint-disable-next-line
    const statuses = getSlicedStatuses(ALL_OPTIONS.REGISTRATION_REQUESTED.value);

    return statuses.includes(value.value.status);
  });

  const isCompletionApprovalRequested = computed(() => {
    if (isCreate.value) {
      return false;
    }

    // eslint-disable-next-line
    const statuses = getSlicedStatuses(ALL_OPTIONS.COMPLETION_APPROVAL_REQUESTED.value);

    return statuses.includes(value.value.status);
  });

  const hasTrainingRequiresRegistrationApproval = computed(() => {
    if (isCreate.value) {
      return false;
    }

    // eslint-disable-next-line
    return value.value?.training?.requires_registration_approval === ALL_OPTIONS.YES.value;
  });

  const hasTrainingRequiresCompletionApproval = computed(() => {
    if (isCreate.value) {
      return false;
    }

    // eslint-disable-next-line
    return value.value?.training?.requires_completion_approval === ALL_OPTIONS.YES.value;
  });

  const hasRegistratonCompletedOn = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const isRequested = isCompletionApprovalRequested;
    const hasApproval = hasTrainingRequiresCompletionApproval;

    return isRequested && hasApproval;
  });

  const hasRegistrationCompletedBy = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const isCompleted = value.status === ALL_OPTIONS.COMPLETED.value;
    const hasApproval = hasTrainingRequiresCompletionApproval;

    return isCompleted && hasApproval;
  });

  const hasRegistrationRequestedOn = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const hasApproval = hasTrainingRequiresRegistrationApproval;

    return isRegistrationRequested && hasApproval;
  });

  const hasRegistrationRequestedBy = computed(() => {
    if (isCreate.value) {
      return false;
    }

    const hasApproval = hasTrainingRequiresRegistrationApproval;

    return isRegistered && hasApproval;
  });

  // METHODS
  // eslint-disable-next-line
  const getStatusIndex = value => options.value.status.findIndex(option => option.value === value);
  const sliceStatusByIndex = index => options.value.status.slice(index);
  const getStatusValues = statuses => statuses.map(option => option.value);

  const getSlicedStatuses = value => {
    const index = getStatusIndex(value);
    const statuses = sliceStatusByIndex(index);
    return getStatusValues(statuses);
  };

  return {
    registeredViaOptions,
    roleOptions,
    roleValues,
    hasRegistratonCompletedOn,
    hasRegistrationCompletedBy,
    hasRegistrationRequestedOn,
    hasRegistrationRequestedBy,
    isInProgress,
    isRegistered,
    hasResult,
    isRegistrationRequested,
    isCompletionApprovalRequested
  };
}
